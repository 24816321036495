var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-send-invoice",
      staticStyle: { "text-align": "left" },
      attrs: {
        visible: _vm.dialogSendInvoice,
        "close-on-click-modal": false,
        width: "50%",
        title: "Send Invoice",
      },
      on: { close: _vm.handleSendInvoiceDialogClose },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "sendInvoiceForm", attrs: { model: _vm.sendInvoiceForm } },
            [
              _c(
                "h3",
                { staticStyle: { "text-align": "left", "margin-top": "0" } },
                [_vm._v("Email:")]
              ),
              _c("p", [
                _vm._v("(Multiple emails to be seperated by semicolon)"),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "invoiceSendEmails",
                    rules: [
                      {
                        required: true,
                        validator: this.sendEmailValidation,
                        trigger: ["blur"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.loading },
                    model: {
                      value: _vm.sendInvoiceForm.invoiceSendEmails,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendInvoiceForm, "invoiceSendEmails", $$v)
                      },
                      expression: "sendInvoiceForm.invoiceSendEmails",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleSendInvoiceDialogClose(false)
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", loading: _vm.loading },
              on: { click: _vm.sendInvoice },
            },
            [_vm._v(" Send ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }